import React from 'react';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { ethers } from 'ethers';

function App() {

  const contractAddress = "0x69877A8c02494D901cfC76083d37639805d79e07";
  const abi = [{"inputs":[],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[{"internalType":"address","name":"cOwner","type":"address"},{"internalType":"address","name":"marketingWallet","type":"address"},{"internalType":"string","name":"name","type":"string"},{"internalType":"string","name":"symbol","type":"string"},{"internalType":"uint256","name":"totalSupply","type":"uint256"},{"internalType":"uint256","name":"taxFee","type":"uint256"}],"name":"createToken","outputs":[{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"theOwner","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address payable","name":"beneficiary","type":"address"}],"name":"withdrawEther","outputs":[],"stateMutability":"nonpayable","type":"function"}];
  const fee = "20000000000000000";
  
  const [currentAccount, setCurrentAccount] = useState(null);
  const [nameText, setNameText] = React.useState(null);
  const [symbolText, setSymbolText] = React.useState(null);
  const [supplyText, setSupplyText] = React.useState(null);
  const [taxText, setTaxText] = React.useState(null);
  const [ownerText, setOwnerText] = React.useState(null);
  const [marketingText, setMarketingText] = React.useState(null);

  const checkFields = () => {
    if (nameText === null){
      alert("Please enter token name");
      return false;
    }
    if (nameText.length < 3){
      alert("Please enter valid token name");
      return false;
    }
    if (symbolText === null){
      alert("Please enter token symbol");
      return false;
    }
    if (symbolText.length < 2){
      alert("Please enter valid token symbol");
      return false;
    }
    if (supplyText === null){
      alert("Please enter token supply");
      return false;
    }
    if (Number(supplyText) < 1){
      alert("Please enter valid token supply");
      return false;
    }
    if (taxText === null){
      alert("Please enter tax from 0 to 10");
      return false;
    }
    if (Number(taxText) < 0 || Number(taxText) > 10){
      alert("Please enter valid tax from 0 to 10");
      return false;
    }
    if (ownerText === null){
      alert("Please enter owner wallet");
      return false;
    }
    if (marketingText === null){
      alert("Please enter marketing wallet");
      return false;
    }
    return true;
  }

  const handleNameChange = (event) => {
    setNameText(event.target.value);
  };

  const handleSymbolChange = (event) => {
    setSymbolText(event.target.value);
  };

  const handleSupplyChange = (event) => {
    setSupplyText(event.target.value);
  };

  const handleTaxChange = (event) => {
    setTaxText(event.target.value);
  };

  const handleOwnerChange = (event) => {
    setOwnerText(event.target.value);
  };

  const handleMarketingChange = (event) => {
    setMarketingText(event.target.value);
  };

  const checkWalletIsConnected = () => {
    const { ethereum } = window;
    if(!ethereum){
      console.log("Metamask NOT Installed");
      return;
    }else{
      console.log("Metamask Installed");
    }

   }


  const connectWalletHandler = async() => { 
    const { ethereum } = window;
    if(!ethereum){
      alert("Please Install Metamask!");
    }

    if(window.ethereum.networkVersion !== '56'){
      console.log("Please change the MetaMask network to Binance Smart Chain", window.ethereum.networkVersion);
      alert("Please change the MetaMask network to Binance Smart Chain Mainnet");
      return;
    }

    try{
      const accounts = await ethereum.request({method: 'eth_requestAccounts'});
      console.log("Found an account :", accounts[0]);
      setCurrentAccount(accounts[0]);
    }catch (err){
      console.log(err);
    }
  }

  const connectWalletButton = () => {
    return (
      <button onClick={connectWalletHandler} className="btn btn-primary">Wallet Connect</button>
    )
  }

  const createButton = () => {
    return (
      <button onClick={createToken} className="btn btn-primary">Create Token</button>
    )
  }

  useEffect(() => {
    checkWalletIsConnected();
  }, [])

  const createToken = async() => {
    if(!checkFields()){
      return;
    }
    try{
      const { ethereum } = window;

      if(ethereum){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Intialize payment");
        let createToken = await contract.createToken(ownerText, marketingText, nameText, symbolText, supplyText, taxText, {value: fee});
        console.log(contract);
    if(createToken){
      alert("Congratulations, your token has been created on smart chain.");
    }else{
      alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
    }
      }
    }catch(err){
    alert("Something wrong, Some fields missing or maybe you don't have enough BNB balance for transaction fee.");
      console.log(err);
    }

  }

  // Custom styles for the card to ensure it fits the dark theme
  const cardStyle = {
    borderRadius: '20px',
    backgroundColor: '#343a40', // Dark background for the card
    color: '#ffffff', // Light text color for contrast
    marginTop: '20px'
  };

  const containerStyle = {
    maxWidth: '600px',
    marginTop: '20px'
  };

  return (
    <div className="container mt-5" style={containerStyle}>
      <div className="card text-center" style={{ ...cardStyle, padding: '10px 0' }}>
        <h2>Create BEP20 Token</h2>
      </div>

      <div className="card border-0 shadow" style={cardStyle}>
        <div className="card-body">
          
            <div className="form-group">
              <label htmlFor="name" className="label-padding">Token Name</label>
              <input type="text" onChange={handleNameChange} value={nameText} className="form-control" id="name" placeholder="Bitcoin" style={{backgroundColor: '#495057', borderColor: '#495057', color: '#ffffff'}} />
            </div>
            <div className="form-group">
              <label htmlFor="symbol" className="label-padding">Symbol</label>
              <input type="text" onChange={handleSymbolChange} value={symbolText} className="form-control" id="symbol" placeholder="BTC" style={{backgroundColor: '#495057', borderColor: '#495057', color: '#ffffff'}} />
            </div>
            <div className="form-group">
              <label htmlFor="supply" className="label-padding">Total Supply</label>
              <input type="number" onChange={handleSupplyChange} value={supplyText} className="form-control" min="1" id="supply" placeholder="21000000" style={{backgroundColor: '#495057', borderColor: '#495057', color: '#ffffff'}} />
            </div>
            <div className="form-group">
              <label htmlFor="tax" className="label-padding">Tax %</label>
              <input type="number" onChange={handleTaxChange} value={taxText} className="form-control" min="0" max="10" id="tax" placeholder="4" style={{backgroundColor: '#495057', borderColor: '#495057', color: '#ffffff'}} />
            </div>
            <div className="form-group">
              <label htmlFor="owallet" className="label-padding">Owner Wallet</label>
              <input type="text" onChange={handleOwnerChange} value={ownerText} className="form-control" id="owallet" placeholder="0x......." style={{backgroundColor: '#495057', borderColor: '#495057', color: '#ffffff'}} />
            </div>
            <div className="form-group">
              <label htmlFor="mwallet" className="label-padding">Marketing Wallet</label>
              <input type="text" onChange={handleMarketingChange} value={marketingText} className="form-control" id="mwallet" placeholder="0x......." style={{backgroundColor: '#495057', borderColor: '#495057', color: '#ffffff'}} />
            </div>
            <br />
            <div className="text-center">
              {currentAccount ? createButton() : connectWalletButton()}
            </div>
         
        </div>
      </div>
    </div>
  );
}

export default App;
